.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    height: 25px;
    width: 175px;
    margin-top: 20px;
    font-size: 15px;
    padding-left: 5px;
    border: 2px solid darkmagenta;
    color: magenta;

    &:focus {
      outline: none;
      border-color: magenta;
    }

    &::placeholder {
      color: rgb(199, 197, 197);
    }
  }

  .fa {
    font-size: 2.5rem;
    &:hover {
      cursor: pointer;
      color: darkmagenta;
    }
  }
}
