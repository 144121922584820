* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: monospace;
}

.app {
  display: grid;
  grid-template-columns: 200px 1fr auto;
  .topBar {
    grid-area: 1 / 1 / span 1 / span 5;
  }
  .sideBar {
    grid-area: 2 / 1 / auto / span 1;
  }
}
