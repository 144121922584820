.sideBar {
  display: flex;
  flex-direction: column;
  background-color: #32746d;
  height: 100%;
  width: 200px;
  box-shadow: 1px 0 5px 1px magenta;
  margin-right: 10px;
  margin-top: 100px;
  padding-top: 75px;
  position: fixed;
  top: 0;
  left: 0;
  .sideBarTitle {
    text-align: center;
    text-shadow: 1px 1px darkmagenta;
    h2 {
      margin-bottom: 50px;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
  .checkList {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 1.15rem;
    text-shadow: 0px 0px 1px darkmagenta;
    font-weight: bolder;
    align-items: left;
    margin-bottom: 20px;
    span {
      padding-left: 8px;
    }
    input {
      cursor: pointer;
      margin: 12px 0;
    }
  }
  .searchTitle {
    text-align: center;
    text-shadow: 1px 1px darkmagenta;
    padding-top: 40px;
    h3 {
      font-size: 1.4rem;
      margin-bottom: 25px;
    }
  }
}
